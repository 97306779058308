import React from "react";
import { Link, NavLink } from "react-router-dom";
import menuImg from "../assets/images/menu.svg";
import logo from "../assets/images/atmosphere-living-text-logo.svg";
import logoWithCircle from "../assets/images/atmosphere-hotel-resort-logo.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useState,useRef,useEffect } from "react";
import Modals from "./Modals";
import { getWebMenu } from "../services/dataServices";
import { useQuery } from "react-query";
const Header = ({ pageNoteFoundBackground }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navbarRef = useRef(null);
  // const [menuItem, setMenuItem] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);  const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
          setExpanded(false);
      }
  };  const handleScroll = () => {
      setExpanded(false);
      setIsMobile(window.innerWidth <= 767 && window.scrollY === 0);
  };  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    window.addEventListener('scroll', handleScroll);    return () => {
        document.removeEventListener('click', handleClickOutside);
        window.removeEventListener('scroll', handleScroll);
    };
}, []);  const navigationItems = useQuery("navigationItems", getWebMenu);  const middleIndex = Math.floor(navigationItems.data?.data?.length / 2);
  const renderSubMenu = (items, parentTitle) => {
    return (
      <NavDropdown
        title={parentTitle}
        className="text-white nav-link"
        id="basic-nav-dropdown"
      >
        {items.map((subItem) => (
          <React.Fragment key={subItem.menuId}>
            {subItem.child ? (
              <NavDropdown.Item
                title={subItem.title}
                id={`submenu-${subItem.menuId}`}
              >
                {renderSubMenu(subItem.child, subItem.title)}
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item>
                <NavLink className="text-white nav-link" to={subItem.slug}>
                  <span>{subItem.title}</span>
                </NavLink>
              </NavDropdown.Item>
            )}
          </React.Fragment>
        ))}
      </NavDropdown>
    );
  };
  
  return (
    <>
      <header className={`start-0 top-0 end-0 z-3 ${pageNoteFoundBackground}`}>
        <div className="header-wrap position-relative">
          <div className="container-fluid">
            <div className="row position-relative">
              <button className="menu-btn border-0 bg-transparent position-absolute z-2 d-none">
                <img src={menuImg} alt="Menu" title="Menu" />
              </button>
              {/* /col */}
              <div className="col d-lg-none">
                <div className="logo-device-col">
                  <div className="device-logo position-relative">
                    <Link to="/">
                      <img
                        src={logo}
                        title="Atmosphere Living"
                        alt="Atmosphere Living"
                      />
                    </Link>
                  </div>
                  <div className="mobile-book-now d-lg-none">
                    <Button
                      className="btn outline-success text-white book-btn text-uppercase px-4 py-2 book_now_header_ga4"
                      onClick={handleShow}
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="atmosphere-living-nav" ref={navbarRef}>
                  <Navbar
                    expanded={expanded}
                    expand="lg"
                    className="navbar-nav smooth"
                  >
                    <Navbar.Toggle
                      className="shadow-none"
                      aria-controls="basic-navbar-nav"
                      onClick={() => setExpanded((prev) => !prev)}
                    />
                    <Navbar.Collapse id="basic-navbar-nav" className="w-100">
                      {/* <Nav as='ul' className="me-auto d-flex align-items-center text-uppercase"> */}
                      <ul className="nav-ul start-nav-list me-auto d-flex align-items-center text-uppercase navbar-nav">
                        {navigationItems.data?.data
                          ?.slice(0, middleIndex + 1)
                          .map((item) => (
                            <li
                              className="nav-item"
                              key={item.menuId}
                              onClick={() => setExpanded(!expanded)}
                            >
                              {item.child ? (
                                renderSubMenu(item.child, item.title)
                              ) : (
                                <NavLink
                                  className="text-white nav-link"
                                  to={item.slug}
                                >
                                  <span>{item.title}</span>
                                </NavLink>
                              )}
                            </li>
                          ))}
                      </ul>
                      <div className="atmosphere-centered-logo ms-auto me-auto">
                        <NavLink onClick={() => setExpanded(!expanded)}
                          className="text-white position-relative d-flex"
                          to="/">
                          <img
                        // <NavLink
                        //     onClick={() => setExpanded(!expanded)}
                        //     className="text-white position-relative d-flex"
                        //     to="/"
                        //   >
                            // <img
                              className="position-absolute start-0 logo-fixed"
                              src={logo}
                              title="Atmosphere Living"
                              alt="Atmosphere Living"
                            />
                            <img
                              className="position-absolute start-0  logo-sticky"
                              src={logoWithCircle}
                              title="Atmosphere Living"
                              alt="Atmosphere Living"
                            />
                          </NavLink>
                      </div>
                      <ul className="nav-ul end-nav-list me-auto d-flex align-items-center text-uppercase navbar-nav">
                        {navigationItems.data?.data
                          ?.slice(middleIndex + 1)
                          .map((item) => (
                            <li
                              className="nav-item"
                              key={item.key}
                              onClick={() => setExpanded(!expanded)}
                            >
                              {item.child ? (
                                renderSubMenu(item.child, item.title)
                              ) : (
                                <NavLink
                                  className="text-white nav-link"
                                  to={item.slug}
                                >
                                  <span>{item.title}</span>
                                </NavLink>
                              )}
                            </li>
                          ))}
                        <li
                          onClick={() => setExpanded(!expanded)}
                          className="booking-nav-item d-none d-lg-flex"
                        >
                          <Button
                            className="btn outline-success book-btn text-uppercase px-4 py-2 book_now_header_ga4"
                            onClick={handleShow}
                          >
                            Book Now
                          </Button>
                        </li>
                        <Modals show={show} handleClose={handleClose} />
                      </ul>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
                {/* /atmosphere-living-nav */}
              </div>
              {/* /col */}
            </div>
            {/* /row */}
          </div>
          {/* container-fluid */}
        </div>
      </header>
    </>
  );
};export default Header;


