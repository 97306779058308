import "./App.css";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import { Home } from "./pages/home/Home";
import AllRoutes from "./allRoutes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Popup from "./components/Popup";
import { useState,useContext } from "react";
import { MyContext } from "../src/Contex/LoadingContex";

function App() {
  const [errorPage, setErrorPage] = useState(false);
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
 
  return (
    <>
     

     {/* { errorPage===false?<Header  />:<Header  pageNoteFoundBackground={'page-note-found-header'}/>}
      <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />
      
      {<Footer/>}
      <ToastContainer/> */}
      { errorPage === true ? (
        <Header pageNoteFoundBackground={"page-note-found-header"} />
      ) : isLoadingMain ? (
       <Loader/>
      ) : (
        <Header  />
      )}
     
      <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />
      {/* {isLoadingMain ? <></> : <Footer />} */}
      {errorPage === true ? (<Footer />):isLoadingMain ? <></> : <Footer />}
      {/* {<Footer />} */}
      <ToastContainer />
    </>
  );
}

export default App;
