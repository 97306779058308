import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Image from '../components/Image';

function ThumbPressItem({ id, thumbCardImage, thumbCardTitle, thumbCardDate, thumbPressLink ,imgAltTag,slugOfMedia}) {
  const navigate = useNavigate();
  return (
    <>

      <div className='thumb-press-card' onClick={() => navigate(`/${slugOfMedia}/${id}`)}>
        <div className='thumb-card-img position-relative rounded-3 overflow-hidden'>
          <Image className='w-100' src={thumbCardImage} alt={imgAltTag} title='' />
          <span className='position-absolute end-0 top-0 rounded-3 px-2 py-1 d-none'>{thumbCardDate}</span>
        </div>
        <div className='thumb-card-title mt-md-3 mt-2'>
          <div className='thumb-press-title'>
            <Link to={thumbPressLink} className='georgia text-decoration-none text-dark h6 text-uppercase'>{thumbCardTitle}</Link>
          </div>
          <span className='opacity-75'>{new Date(thumbCardDate).toLocaleDateString("en-GB")}</span>
        </div>
      </div>

    </>
  )
}

export default ThumbPressItem