import React,{useContext } from "react";
import HomeVideo from "../../components/HomeVideo";
import BookNow from "../../components/BookNow";
import ExperienceAwait from "../../components/ExperienceAwait";
import OurSubBrands from "../../components/OurSubBrands";
import HotelsResorts from "../../components/HotelsResorts";
import OurGallery from "../../components/OurGallery";
import SpecialOffers from "../../components/SpecialOffers";
import Sustainability from "../../components/Sustainability";
import { useQuery } from "react-query";
import { MyContext } from "../../Contex/LoadingContex";
import { getPageData,getPageData1 } from "../../services/dataServices";

export const Home = ({isPreview, userId, sectionId,slugOfOurStory,slugOfOffers,slugOfSustainbility}) => {
  const para = `<p>Staying in a hotel is more than a mere check-in; it’s about signing up for an experience outside of what you’ve had before in life. It’s not a mere getaway but an opening to the possibility of feeling at home outside of your home. And in a world of sameness, a memorable hotel stay offers all this and more by blending in a different perspective to hospitality and luxury, one that’s enriching, authentic & crafted with attention to detail. Welcome to our immersive world of Atmosphere Hotels and Resorts, where exploring life finds new meanings. It is a stay that stays with you, even long after you check out.</p>`;
  const pageCode = "HOME";
  const pagesSectionData1 = useQuery(["pagesSectionData1", pageCode,isPreview, userId, sectionId], () =>
    getPageData1(pageCode,isPreview, userId, sectionId)
  );
  const {isLoading,isSuccess}=pagesSectionData1
  // if(!pagesSectionData1.data){
  //   <>Loading..</>
  //   }
const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
if(isLoading){
  setIsLoadingMain(true)
  return
}
if(isSuccess){
  setIsLoadingMain(false)
}
  return (
    <>
      <HomeVideo pagesSectionData1={pagesSectionData1}/>
      <BookNow />
      <ExperienceAwait para={para} isPreview={isPreview} userId={userId} sectionId={sectionId} slugOfOurStory={slugOfOurStory}/>
      <OurSubBrands isPreview={isPreview} userId={userId} sectionId={sectionId} />
      <HotelsResorts isPreview={isPreview} userId={userId} sectionId={sectionId} />
      {/* <OurGallery isPreview={isPreview} userId={userId} sectionId={sectionId}  /> */}
      <SpecialOffers slugOfOffers={slugOfOffers}/>
      <Sustainability isPreview={isPreview} userId={userId} sectionId={sectionId} slugOfSustainbility={slugOfSustainbility}  />
    </>
  );
};
